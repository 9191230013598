<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>About Us</h2>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-three/about.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>About Us: Health1st Solutions.</h2>
                    <p>Welcome to Health1stSolutions, a pioneering health company nestled in the heart of San Diego. We take pride in being at the forefront of healthcare innovation, providing a diverse range of services that prioritize accessibility, affordability, and personalized care. Our mission is clear - to make healthcare cheaper,
                         faster, and more reachable, with a particular emphasis on serving lower-income communities.</p>

                    <p>At Health1stSolutions, we understand that health is not a privilege; it's a fundamental right. Our cornerstone services include state-of-the-art telehealth solutions, allowing individuals to connect seamlessly with board-certified doctors and licensed mental health therapists from the comfort of their homes. We believe that quality healthcare should transcend barriers, 
                        and our telehealth services are designed to ensure just that.</p>
                   
                </div>
            </div>
            <div>
                <p>
                    In addition to telehealth, Health1stSolutions is a trailblazer in Pharmacogenomics (PGX) testing. This groundbreaking approach empowers individuals to optimize their medication regimens, ensuring a more personalized and effective healthcare experience. Our commitment to making healthcare more affordable extends to our Discount Rx card, providing substantial savings on prescription medications, 
                    because no one should have to compromise on their health due to financial constraints.
                </p>
                <p>
                    As a collaborative force for health and well-being, Health1stSolutions partners with a network of service providers. This collaborative approach allows us to extend our reach and tailor our services to meet the diverse needs of individuals, businesses, communities, and schools. 
                    By working with schools and communities, we aim to foster a culture of proactive health management and education. 
                </p>

                <p>Health1stSolutions is more than a healthcare provider; we are a community-focused ally dedicated to empowering individuals and organizations in their quest for optimal health. Join us in reshaping the healthcare landscape, where accessibility and affordability are not just promises but a tangible reality for all. Your health comes first, and at Health1stSolutions, 
                    we are committed to making that belief a cornerstone of our service.</p>
            </div>
        </div>
    </div>
</div>

<!--<div class="counter-area counter-bg counter-area-four">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">850</h3>
                    <p>Patients Beds</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors  & Nurse</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">18</h3>
                    <p>Year Experience</p>
                </div>
            </div>
        </div>
    </div>
</div>-->


<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Services</span>
            <h2>Our Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Tele Health</h3>
                        <p>24/7 access to doctors, and mental health support, all from your own home.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Tele Health</h3>
                        <p>24/7 access to doctors, and mental health support, all from your own home.</p>
                        <a routerLink="/telehealth">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>PGx Test</h3>
                        <p>Unlock the power of your genetic code to ensure your medications work best for you.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>PGx Test</h3>
                        <p>Unlock the power of your genetic code to ensure your medications work best for you</p>
                        <a routerLink="/pgxtest">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Discount Rx</h3>
                        <p>Savings up to 55% on generic prescriptions and up to 15% on brand prescriptions.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Discount Rx</h3>
                        <p>Savings up to 55% on generic prescriptions and up to 15% on brand prescriptions.</p>
                        <a routerLink="/discountRx">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Remote Patient Monitoring</h3>
                        <p>Let's get you feeling good again.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Remote Patient Monitoring</h3>
                        <p>Let's get you feeling good again.</p>
                        <a routerLink="/remotepatientmonitoring">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="testimonial-wrap">
            <h2>What they say</h2>
            
            <div class="testimonial-slider owl-theme owl-carousel">
                <div class="testimonial-item">
                    <img src="assets/img/home-three/7.png" alt="Testimonial">
                    <h3>John Mic</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/8.png" alt="Testimonial">
                    <h3>Adam Smith</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/9.png" alt="Testimonial">
                    <h3>Jac Jacson</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>
            </div>
        </div>
    </div>
</section>

