import { Component } from '@angular/core';

@Component({
  selector: 'app-discount-rx',
  templateUrl: './discount-rx.component.html',
  styleUrls: ['./discount-rx.component.scss']
})
export class DiscountRxComponent {

}
