import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators ,ReactiveFormsModule} from '@angular/forms';
import { first } from 'rxjs/operators';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from "firebase/database";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  constructor() { }

  ngOnInit(): void {

    this.registerForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
      dob: new FormControl(''),
      gender: new FormControl(''),
      zipCode: new FormControl('', Validators.minLength(5)),
      note: new FormControl('')
    });

  }


  ifRegisterFormValid(){
    if (this.registerForm.controls['firstName'].value == '' || this.registerForm.controls['lastName'].value == '' || 
      this.registerForm.controls['email'].value == '' || this.registerForm.controls['phone'].value == '' || this.registerForm.controls['reason'].value == '') {
      return false;
    }

    if (this.registerForm.controls['reason'].value == 'telehealth') {
      if ((this.registerForm.controls['dob'].value == '') || (this.registerForm.controls['zipCode'].value == '') || (this.registerForm.controls['gender'].value == '')) {
        return false;
      }
    }
    return true
  }

  onSubmit(){
    if (!this.ifRegisterFormValid()) {
      return;
    }

    // Initialize Firebase
    const realtimeDB = this.initializeFirebase();
    let id = "";
    if (this.registerForm.controls['reason'].value == 'telehealth') {
      id = 'uplandTelehealth/' + this.registerForm.controls['firstName'].value + '-' + this.registerForm.controls['phone'].value;
    } else if (this.registerForm.controls['reason'].value == 'pgx') {
      id = 'pgx/' + this.registerForm.controls['firstName'].value + '-' + this.registerForm.controls['phone'].value;
    } else {
      id = 'general/' + this.registerForm.controls['firstName'].value + '-' + this.registerForm.controls['phone'].value;
    }

    const newRegisterRef = ref(realtimeDB,  id);
    console.log(this.registerForm.controls['firstName'].value);
    set(newRegisterRef, {
      firstName: this.registerForm.controls['firstName'].value,
      lastName: this.registerForm.controls['lastName'].value,
      email: this.registerForm.controls['email'].value,
      phone: this.registerForm.controls['phone'].value,
      reason: this.registerForm.controls['reason'].value,
      dob: this.registerForm.controls['dob'].value,
      gender: this.registerForm.controls['gender'].value,
      zipCode: this.registerForm.controls['zipCode'].value,
      note: this.registerForm.controls['note'].value
    });

    alert('Your information has been saved successfully!');
    this.registerForm.reset();


  }

  initializeFirebase(){
    // Your web app's Firebase configuration
    var firebaseConfig = {
      apiKey: "AIzaSyBbomBm_1ybnJQSeBMfSq-IO80mCADtRrc",
      authDomain: "hfs-website-424fa.firebaseapp.com",
      databaseURL: "https://hfs-website-424fa-default-rtdb.firebaseio.com",
      projectId: "hfs-website-424fa",
      storageBucket: "hfs-website-424fa.appspot.com",
      messagingSenderId: "327959147581",
      appId: "1:327959147581:web:da02eccbc188170ce06654",
      measurementId: "G-GL67WJTZPE"
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const realtimeDB = getDatabase(app);

    return realtimeDB;
  }

}
