<div class="signup-area">
    <div class="container-fluid">
        <div class="row">
            

            <div class="col-lg-12 ptb-100">
                <div class="signup-item">
                    <div class="signup-head">
                        <h2>Register Here</h2>                        
                    </div>

                    <div class="signup-form">
                        <form [formGroup] = "registerForm" >
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="firstName">First Name</label> <br />
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="firstName" placeholder="First Name">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="lastName">Last Name</label> <br />
                                        <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="email">Email</label> <br />
                                        <input type="email" class="form-control" formControlName="email" placeholder="Your Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="phone">Phone Number</label> <br />
                                        <input type="phone" class="form-control" formControlName="phone" placeholder="Phone Number">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="reason">Select a reason</label> <br />
                                        <select id = "reason" class="form-control" formControlName="reason"> 
                                            <option value="pgx">PGx Testing</option>
                                            <option value="telehealth">Upland Telehealth Register</option>
                                            <option value="general">General Register</option>

                                        </select>
                                    </div>
                                </div>

                                  
                                
                                <div *ngIf = "this.registerForm.controls['reason'].value === 'telehealth'" class="col-lg-6">
                                    <div class="form-group">
                                        <label for="dob">Date Of Birth</label> <br />
                                        <input type="date" required class="form-control" formControlName="dob" placeholder="Date of Birth">
                                    </div>
                                </div>
                                <div *ngIf = "this.registerForm.controls['reason'].value === 'telehealth'" class="col-lg-6">
                                    <div class="form-group">
                                        <label for="dob">Zip Code</label> <br />
                                        <input type="number" class="form-control" formControlName="zipCode" placeholder="Zip Code">
                                    </div>
                                </div>

                                <div *ngIf = "this.registerForm.controls['reason'].value === 'telehealth'" class="col-lg-6">
                                    <div class="form-group">
                                        <label for="gender">Gender</label> <br />
                                        <select id = "gender" class="form-control" formControlName="gender"> 
                                            <option value="m">Male</option>
                                            <option value="f">Female</option>
                                            <option value="u">Unknown</option>
                                        </select>
                                    </div>
                                </div>


                                

                                <div class="col-lg-12">
                                    <label for="gender">Comment</label> <br />
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="note" placeholder="Note">
                                    </div>
                                </div>

            

                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <button type="submit" [disabled] = "!ifRegisterFormValid()" (click)="onSubmit()" class="btn signup-btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div>{{ifRegisterFormValid()}}</div>

