import { Component } from '@angular/core';

@Component({
  selector: 'app-tele-health',
  templateUrl: './tele-health.component.html',
  styleUrls: ['./tele-health.component.scss']
})
export class TeleHealthComponent {

}
