<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>HFS Telehealth</h2><p class="doctegrity"><u>Powered By Doctegrity</u><p>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-three/telehealth-1.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h3>Welcome to HFS Telehealth.</h3>
                    <p>At HFS, we believe in bringing healthcare to your fingertips. Our telehealth services powered by Doctegrity empower you to access top-notch medical care from the comfort of your home or anywhere you are. With cutting-edge
                         technology and a team of experienced healthcare professionals, we are dedicated to making healthcare convenient, accessible, and personalized.</p>
                    
                    <h3>How does it work?</h3>
                    <p>Utilize telehealth to communicate with our doctors or therapists via phone or video using our app. Subscribing to HFS Telehealth powered by Doctegrity provides you and your family with on-demand access to board-certified primary care doctors and licensed
                         mental health therapists through an affordable monthly subscription. No copays, extra fees, or unexpected bills.</p>

                    <h4>Starting at $29/month</h4>
                    <div class="telehealth-btn">
                        <a class="cmn-btn-right-two" href="https://www.doctegrity.com/become-a-member/" target="_blank" rel="noopener noreferrer">Become a Member</a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-70">
    <div class="container">
        <div class="section-title-two">
            <span>How it works?</span>
            <h3>Never been so easy!</h3>
        </div>
        <div class="row">
            <!--<div class="col-sm-6 col-lg-6">
                <div class="department-item">
                    <i class="icofont-hospital"></i>
                    <h3>Primary Care Online</h3>
                    <p>Our board-certified doctors will assess your symptoms and provide online diagnosis and prescription if necessary. You can choose which 
                        pharmacy you want your prescription sent to no matter where you are. Here are some of the conditions we treat:</p>
                    <div>
                        <ul>
                            <li>Abdominal pain/cramps</li>
                            <li>Book your appointments at your convenience.</li>
                            <li> Get the care you need.</li>
                        </ul>
                    </div>
                </div>

            </div>
            
            <div class="col-sm-6 col-lg-6">
                <div class="department-item">
                    <i class="icofont-brain-alt"></i>
                    <h3>Telehealth online therapy</h3>
                    <p>Our certified telehealth therapists and counselors are available around the clock, 
                        whenever you need them. They’re ready to talk to you, no matter whether the issue is big or small.</p>
                    <div class="telehealth-btn">
                        <a class="cmn-btn-right-two" href="https://www.doctegrity.com/become-a-member/" target="_blank" rel="noopener noreferrer">Become a Member</a>
                    </div>
                </div>
            </div>-->

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-laptop"></i>
                    <h3>Sign In</h3>
                    <p>Sign up, choose your telehealth package, and complete a quick questionnaire for our doctors to better understand your needs. </p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-ui-calendar"></i>
                    <h3>Schedule & Connect</h3>
                    <p>Schedule video or audio consultations with our healthcare professionals. Discuss your health concerns and get personalized treatment plans.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-doctor"></i>
                    <h3>Consult</h3>
                    <p>Talk to our medical experts, discuss your concerns, and receive expert advice.  If necessary, schedule follow-up appointments. </p>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Why Choose Us?</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Accessible Care</h3>
                                    <p> No more waiting rooms or long commutes. With HFS Telehealth, quality healthcare is just a 
                                        click away. Schedule appointments, consult with doctors, and get prescriptions online.</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Secure and Private</h3>
                                    <p> Our platform uses advanced encryption and security measures to safeguard your personal 
                                        and medical information. Feel confident that your data is in safe hands</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>User-Friendly Platform</h3>
                                    <p>Our telehealth platform is designed with your convenience in mind. It's easy to navigate, and our user-friendly interface
                                         ensures a seamless experience from appointment scheduling to virtual consultations.</p>
                                </div>
                            </div>



                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Experienced Healthcare Professionals</h3>
                                    <p>Our team comprises highly skilled and certified doctors, nurses, and specialists who are committed 
                                        to providing you with the best care possible. You are in expert hands.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item telehealth-right">
                    <img src="assets/img/home-three/telehealth-2.jpg" alt="Speciality">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="counter-area counter-bg counter-area-four">
    <div class="container">
        <div class="row">
            <!--<div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">850</h3>
                    <p>Patients Beds</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors  & Nurse</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">18</h3>
                    <p>Year Experience</p>
                </div>
            </div>-->
            <p>Reduce premiums with high quality telemedicine for employees.</p>
            <h3>Accessible healthcare for your employees & Affordable group pricing for your company.</h3>
            <div class="telehealth-btn">
                <a class="cmn-btn-right-two" routerLink="/contact">Get in touch</a>
            </div>
            
        </div>
    </div>
</div>

<section class="departments-area pt-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="treat-list">
                    <i class="icofont-stethoscope-alt"></i>
                    <h3>Primary Care Online</h3>
                    <p>Connect with a group of certified physicians specializing in a range of medical conditions. Opt for either a phone or video consultation with our doctors, where they will assess your symptoms and, when required, issue a prescription.
                         Your prescription will be seamlessly transmitted to the nearest pharmacy for convenient pickup. Explore some of the conditions we address::</p>

                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">Abdominal pain/cramps</i></li>
                                <li><i class="treat_item icofont-check-circled">Abscess</i></li>
                                <li><i class="treat_item icofont-check-circled">Acid reflux</i></li>
                                <li><i class="treat_item icofont-check-circled">Animal/insect bite</i></li>
                                <li><i class="treat_item icofont-check-circled">Arthritis</i></li>
                                <li><i class="treat_item icofont-check-circled">Asthma</i></li>
                                <li><i class="treat_item icofont-check-circled">Backache</i></li>
                                <li><i class="treat_item icofont-check-circled">Blood pressure issues</i></li>
                                <li><i class="treat_item icofont-check-circled">Bronchitis</i></li>
    
                                
                            </ul>
                        </div>
                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">Bowel/digestive issue</i></li>
                                <li><i class="treat_item icofont-check-circled">Cellutils</i></li>
                                <li><i class="treat_item icofont-check-circled">Cold</i></li>
                                <li><i class="treat_item icofont-check-circled">Constipation</i></li>
                                <li><i class="treat_item icofont-check-circled">Cough/croup</i></li>
                                <li><i class="treat_item icofont-check-circled">Diarrhea</i></li>
                                <li><i class="treat_item icofont-check-circled">Eye infection/irritation</i></li>
                                <li><i class="treat_item icofont-check-circled">Fever</i></li>
                                <li><i class="treat_item icofont-check-circled">Flu</i></li>
    
                                
                            </ul>
                        </div>
                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">Gas</i></li>
                                <li><i class="treat_item icofont-check-circled">Gout</i></li>
                                <li><i class="treat_item icofont-check-circled">Headache/Migraine</i></li>
                                <li><i class="treat_item icofont-check-circled">Herpes</i></li>
                                <li><i class="treat_item icofont-check-circled">Joint pain/swelling</i></li>
                                <li><i class="treat_item icofont-check-circled">Laryngitis</i></li>
                                <li><i class="treat_item icofont-check-circled">Pink eye</i></li>
                                <li><i class="treat_item icofont-check-circled">Poison ivy/oak</i></li>
                                <li><i class="treat_item icofont-check-circled">Respiratory Infection</i></li>
    
                                
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">Sinusitis</i></li>
                                <li><i class="treat_item icofont-check-circled">Sore throat</i></li>
                                <li><i class="treat_item icofont-check-circled">Sprains and strains</i></li>
                                <li><i class="treat_item icofont-check-circled">STDs</i></li>
                                <li><i class="treat_item icofont-check-circled">Strep</i></li>
                                <li><i class="treat_item icofont-check-circled">Tonsillitis</i></li>
                                <li><i class="treat_item icofont-check-circled">Vaginal/menstrual issues</i></li>
                                <li><i class="treat_item icofont-check-circled">Yeast infection</i></li>
                                <li><i class="treat_item icofont-check-circled">And more ....</i></li>
    
                                
                            </ul>
                        </div>

                </div>
            </div>
        </div>
    </div>


    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="treat-list">
                    <i class="icofont-brain-alt"></i>
                    <h3>Telehealth therapy</h3>
                    <p>Quickly and effortlessly connect with a teletherapist to discuss mental health concerns, regardless of their magnitude. 
                        Our therapists and counselors are readily available to support you whenever you require assistance, no matter 
                        where you are in the United States. Some prevalent issues we can assist you with include:</p>

                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">ADHD</i></li>
                                <li><i class="treat_item icofont-check-circled">Addictions</i></li>
                                <li><i class="treat_item icofont-check-circled">Anger Management </i></li>
                                <li><i class="treat_item icofont-check-circled">Anxiety</i></li>
                                <li><i class="treat_item icofont-check-circled">Bipolar Disorders</i></li>
                                <li><i class="treat_item icofont-check-circled">Bullying</i></li>   
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">Career/job-related stress</i></li>
                                <li><i class="treat_item icofont-check-circled">Child & adolescent issues</i></li>
                                <li><i class="treat_item icofont-check-circled">Depression</i></li>
                                <li><i class="treat_item icofont-check-circled">Divorce</i></li>
                                <li><i class="treat_item icofont-check-circled">Eating disorders</i></li>
                                <li><i class="treat_item icofont-check-circled">Grief and loss</i></li>
                              
    
                                
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">Life changes</i></li>
                                <li><i class="treat_item icofont-check-circled">Nutrition counseling</i></li>
                                <li><i class="treat_item icofont-check-circled">Panic disorders</i></li>
                                <li><i class="treat_item icofont-check-circled">Parenting issues</i></li>
                                <li><i class="treat_item icofont-check-circled">Realtionship and Marriage issues</i></li>
                                
    
                                
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-3 treat-column">
                            <ul>
                                <li><i class="treat_item icofont-check-circled">Self-image</i></li>
                                <li><i class="treat_item icofont-check-circled">Stress</i></li>
                                <li><i class="treat_item icofont-check-circled">Substance abuse</i></li>
                                <li><i class="treat_item icofont-check-circled">Trauma and PTSD</i></li>
                                <li><i class="treat_item icofont-check-circled">And more ....</i></li>
                                                               
                            </ul>
                        </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</section>






