<footer class="pb-70">
    <!--<div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <h2>Join Our Newsletter</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                                <button class="btn newsletter-btn" type="submit">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>
                                <a href="mailto:team@health1stsolutions.com">team@health1stsolutions.com</a>
                                
                            </li>
                            <li>
                                <i class="icofont-stock-mobile"></i>
                                <a href="tel:+16199255300">Call: (619) 925-5300</a>
                                
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                402 W. Broadway, STE. 400 <br>
                                San Diego, California 92101
                                
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a routerLink="/about">About us</a></li>
                            <li><a routerLink="/faq">Faq</a></li>
                            <li><a routerLink="/contact">Contact us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Our Services</h3>
                        <ul>
                            <li><a routerLink="/">TeleMedicine</a></li>
                            <li><a routerLink="/pgxtest">PGx Testing</a></li>
                            <li><a routerLink="/">Diabetes Management</a></li>
                            <li><a routerLink="/">Discount Prescription</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-feedback">
                        <h3>Feedback</h3>
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Phone">
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" id="your_message" rows="5" placeholder="Message"></textarea>
                            </div>
                            <div class="text-left">
                                <button type="submit" class="btn feedback-btn">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>© 2023 All Rights Reserved <a href="https://www.health1stsolutions.com/" target="_blank">Health1stSolutions.</a></p>
        </div>
    </div>
</div>