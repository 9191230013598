import { Component } from '@angular/core';

@Component({
  selector: 'app-remote-patient-monitoring',
  templateUrl: './remote-patient-monitoring.component.html',
  styleUrls: ['./remote-patient-monitoring.component.scss']
})
export class RemotePatientMonitoringComponent {

}
