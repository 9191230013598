<div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-9 col-lg-9">
                <div class="header-top-item">
                    <div class="header-top-left">
                        <ul>
                            <li><a href="tel:6199255300"><i class="icofont-ui-call"></i> Call : (619) 925-5300</a></li>
                            <li><a href="team@health1stsolutions.com"><i class="icofont-ui-message"></i> team@health1stsolutions.com</a>
                            </li>
                            <li><i class="icofont-location-pin"></i> San Diego, California </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="header-top-item">
                    <div class="header-top-right">
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/web-logo.jpg" alt="Logo" height="40px" width="100px"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/h1st-logo.png" alt="Logo" height="60px" width="160px"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <!--<li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 3</a></li>
                            </ul>
                        </li>-->
                        <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                       <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Appointment</a></li>
                                
                                <li class="nav-item"><a routerLink="/departments" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Departments</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                                
                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li>

                                <li class="nav-item"><a routerLink="/signup" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li>-->

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/telehealth" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tele Health</a></li>
                                <li class="nav-item"><a routerLink="/pgxtest" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">PGx Testing</a></li>
                                <li class="nav-item"><a routerLink="/discountRx" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Discount Rx</a></li>

                                
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Programs</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="https://app.uplandtelehealth.com/" target="_blank" rel="noopener noreferrer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Upland Telehealth</a></li> 
                                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
                            </ul>
                        </li>

                        <!--<li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>-->

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="nav-srh">
                        <div class="search-toggle">
                            <button class="search-icon icon-search"><i class="icofont-search-1"></i></button>
                            <button class="search-icon icon-close"><i class="icofont-close"></i></button>
                        </div>
                        
                        <div class="search-area">
                            <form>
                                <input type="text" class="src-input" id="search-terms" placeholder="Search here..." />
                                <button type="submit" name="submit" value="Go" class="search-icon"><i class="icofont-search-1"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>