<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Frequently Asked Questions</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Faq</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Tele Health</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                            <a>What is Telehealth?            </a>
                            <p> Telehealth refers to the practice of providing medical care remotely using telecommunications technology. It allows patients to consult with healthcare professionals through video 
                                calls, phone calls, or secure messaging platforms.
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                            <a>How does Telehealth work? </a>
                            <p> Telehealth works by connecting patients and healthcare providers virtually. Patients can schedule appointments, discuss their symptoms, and receive medical advice from the comfort of their own homes. Healthcare providers can assess patients, diagnose conditions, 
                                prescribe medications, and provide necessary healthcare guidance remotely.
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>Is Telehealth as effective as in-person consultations?                            </a>
                            <p> Telehealth has proven to be effective for a wide range of non-emergency medical conditions. 
                                Many studies have shown that Telehealth consultations can yield comparable health outcomes to in-person
                                 visits for various medical concerns. However, certain conditions may still require in-person examinations or specialized tests.
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a> Is Telehealth secure and private?</a>
                            <p> Telehealth platforms prioritize patient privacy and security. They utilize encryption and adhere to strict privacy regulations to 
                                ensure confidential medical information remains protected. It's important to choose a reputable Telehealth provider that complies with 
                                relevant privacy laws.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a> How do I schedule a telehealth appointment?</a>
                            <p> Scheduling a telehealth appointment is easy. Simply log in to your account, choose a convenient time, and select your preferred method of communication 
                                (video or phone). Follow the prompts to complete the appointment booking.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>Can I get prescriptions through telehealth?</a>
                            <p>Yes, healthcare providers can prescribe medications during Telehealth consultations, if appropriate. However, they will need to follow local regulations and 
                                guidelines for prescribing medications remotely. It's crucial to provide accurate medical history and information
                                 to help the provider make informed decisions.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>Are there any additional fees or hidden costs?</a>
                            <p> No, our telehealth service operates on a transparent subscription model. There are no copays, additional fees, or surprise bills. 
                                The subscription covers the services mentioned in your selected package.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a> Can I use telehealth for emergency situations?</a>
                            <p> Telehealth is not suitable for emergencies. In case of a medical emergency, please call your local emergency services immediately. 
                                Telehealth is designed for non-emergency medical issues and routine healthcare needs</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>PGx Testing</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>Is PGx testing safe?</a>
                            <p>Yes, PGx testing is safe and non-invasive. It's performed by experienced genetic professionals in accredited laboratories</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>Can I request PGx testing without doctor's prescription?</a>
                            <p>In some cases, direct-to-consumer PGx testing is available, but it's recommended to discuss PGx testing with your healthcare provider to ensure it aligns with your medical needs.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>Is PGx testing suitable for children?</a>
                            <p>PGx testing can be beneficial for children, especially if they are on long-term medications. However, it's essential to consult with a healthcare provider to determine if PGx testing is appropriate for a child.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>Can PGx testing predict all possibke drug interactions?</a>
                            <p>PGx testing can identify genetic variations that are known to affect how your body metabolizes certain medications. While it can provide valuable information, it may not account for all potential drugs interactions, so continured monitoring and communication with your healthcare provider are essential.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>Why is PGx testing important?</a>
                            <p>PGx testing helps avoid adverse drug reactions, improve treatment efficacy, and reduce the "trial and error" approach to medication selection.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                            <a>Is PGx testing covered by insurance?</a>
                            <p>PGx testing may be covered by some insurance plans. It is recommended to check with your insurance provider for details in coverage.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Discount Rx</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a></a>
                            <p></p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a></a>
                            <p></p>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a></a>
                            <p></p>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a></a>
                            <p></p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a></a>
                            <p></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>