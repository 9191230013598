<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item">
                    <h1>Your Healthy Life is Our First Priority.</h1>
                    
                    <h5><p>24/7 access to doctors, and mental health support, all from your own home.</p></h5>
                    <p>Get easy and convenient access to bilingual, board certified primary care physicians & licensed mental health therapists.</p>

                    <div class="common-btn-two">
                        <a routerLink="/telehealth">Learn More</a>
                        <a class="cmn-btn-right-two" href="https://www.doctegrity.com/become-a-member/" target="_blank" rel="noopener noreferrer">Become a Member</a>
                    </div>

                    <div class="banner-right">
                        <img src="assets/img/home-three/1.png" alt="Banner">
                        <img src="assets/img/home-three/2.png" alt="Banner">
                        <img src="assets/img/home-three/2.png" alt="Banner">
                        <img src="assets/img/home-one/home-bg5.png" alt="Banner">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="heart-shape"><img src="assets/img/home-three/3.png" alt="Banner"></div>
</div>

<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0">
                <div class="hospital-item">
                    <div class="row m-0">
                        <div class="col-lg-6 p-0">
                            <div class="hospital-left-one">
                                <img src="assets/img/home-three/4.jpg" alt="About">
                            </div>
                        </div>

                        <div class="col-lg-6 p-0">
                            <div class="hospital-left-two">
                                <img src="assets/img/home-three/5.jpg" alt="About">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="hospital-item hospital-right">
                    <h2>About Us</h2>
                    <p> Welcome to Health1stSolutions, where your well-being takes precedence. At Health1stSolutions, 
                        we are dedicated to revolutionizing healthcare by providing comprehensive health services that
                        prioritize the needs of individuals, communities, schools, and businesses. Our commitment is to 
                        empower you to take charge of your health through innovative solutions and personalized care.</p>
                    <p>
                        At Health1stSolutions, we envision a future where healthcare is not just a service but a partnership in your journey
                         to a healthier, happier life. Join us in prioritizing your health - because at Health1stSolutions, your well-being comes first
                    </p>
                    <a class="hospital-btn" routerLink="/about">Know More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>TeleMedicine</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>PGx Testing</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Discount Rx</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Diabetes Management</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Services</span>
            <h2>Our Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Tele Health</h3>
                        <p>24/7 access to doctors, and mental health support, all from your own home.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Tele Health</h3>
                        <p>24/7 access to doctors, and mental health support, all from your own home.</p>
                        <a routerLink="/telehealth">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>PGx Test</h3>
                        <p>Unlock the power of your genetic code to ensure your medications work best for you.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>PGx Test</h3>
                        <p>Unlock the power of your genetic code to ensure your medications work best for you</p>
                        <a routerLink="/pgxtest">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Discount Rx</h3>
                        <p>Savings up to 55% on generic prescriptions and up to 15% on brand prescriptions.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Discount Rx</h3>
                        <p>Savings up to 55% on generic prescriptions and up to 15% on brand prescriptions.</p>
                        <a routerLink="/discountRx">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Remote Patient Monitoring</h3>
                        <p>Let's get you feeling good again.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Remote Patient Monitoring</h3>
                        <p>Let's get you feeling good again.</p>
                        <a routerLink="/remotepatientmonitoring">Read More</a>
                    </div>
                </div>
            </div>

            

            <!--<div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>PGx Testing</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="welcome-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-left">
                    <img src="assets/img/home-two/bg3.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-right">
                    <div class="section-title-two">
                        <span>About Us</span>
                        <h2>Welcome to Health1stSolutions. We have...</h2>
                    </div>

                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <i class="icofont-doctor-alt"></i>
                            <div class="welcome-inner">
                                <h3>Patient-Centric Approach</h3>
                                <p>Our focus is on you. We prioritize patient-centered care, ensuring that your unique needs
                                and preferences are at the forefront of your healthcare journey.</p>
                            </div>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <i class="icofont-stretcher"></i>
                            <div class="welcome-inner">
                                <h3>Community Engagement</h3>
                                <p>Health1stSolutions actively engages with local communities, schools, 
                                and businesses to promote health education, awareness, and overall well-being</p>
                            </div>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <i class="icofont-network"></i>
                            <div class="welcome-inner">
                                <h3>Innovation in Healthcare</h3>
                                <p>Embracing the latest advancements in healthcare technology, 
                                    we bring innovation to your doorstep.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<div class="video-wrap video-wrap-two">
    <div class="container-fluid p-0">
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Hospital Introduction</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>About Our Pharmacy</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our reasearch  center and  lab </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-icu" role="tabpanel" aria-labelledby="pills-icu-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>CCU & ICU</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-doctor" role="tabpanel" aria-labelledby="pills-doctor-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our Doctors</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="tab" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Hospital Introduction</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="tab" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">Pharmacy</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="tab" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="true">Reasearch & Lab</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-icu-tab" data-bs-toggle="tab" data-bs-target="#pills-icu" type="button" role="tab" aria-controls="pills-icu" aria-selected="true">CCU & ICU</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-doctor-tab" data-bs-toggle="tab" data-bs-target="#pills-doctor" type="button" role="tab" aria-controls="pills-doctor" aria-selected="true">Doctors</button>
            </li>
        </ul>
    </div>
</div>

<section class="doctors-area pt-100">
    <div class="container">
        <div class="section-title-two">
            <span>Doctors</span>
            <h2>Meet Our Doctors </h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/1.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Babatunde</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/2.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Addision Smith</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/3.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Sarah Tylor</a></h3>
                        <span>Dental Surgeon</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="doctor-btn">
            <a routerLink="/doctor">See All</a>
        </div>
    </div>
</section>

<div class="counter-area counter-area-three">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">850</h3>
                    <p>Patients Beds</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors  & Nurse</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">18</h3>
                    <p>Year Experience</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!--<section class="review-area pb-100">
    <div class="container">
        <div class="main">
            <div class="slider-nav">
                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/7.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Adision Smith</h3>
                        <span>Designer</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/8.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>John Cena</h3>
                        <span>Designer</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/9.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Mac Smith</h3>
                        <span>Designer</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/9.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Shane Watson</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>

            <div class="slider-for">
                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
                </div>

                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
                </div>
                
                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
                </div>

                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
                </div>
            </div>
        </div>
    </div>
</section>-->

<!--<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Blogs</span>
            <h2>Our latest blogs</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">
                    <div class="drop-left">
                        <h2>Drop your message for any info or question.</h2>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Name">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control" placeholder="Email">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" class="form-control" placeholder="Phone">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" class="form-control" placeholder="Subject">
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <button type="submit" class="drop-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                    <img src="assets/img/home-two/bg4.jpg" alt="Contact">
                </div>
            </div>
        </div>
    </div>
</section>
