import { Component } from '@angular/core';

@Component({
  selector: 'app-pgx-test',
  templateUrl: './pgx-test.component.html',
  styleUrls: ['./pgx-test.component.scss']
})
export class PgxTestComponent {

}
