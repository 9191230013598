<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Pharmacogenomic Testing</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>PGx Test</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-one/home-bg4.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h3>What is PGx Testing?</h3>
                    <p>Pharmacogenomic or PGx, is the study of how genetic variations impact an individual's response to drugs. By analyzing specific genes, we can predict how a person's body will metabolize and respond to various medications. PGx testing 
                        can help healthcare providers tailor drug prespictions to individual patients, minimizing adverse effects and maximixing therapeutic benefits</p>
                    
                    <h3>How does PGx testing work?</h3>
                    <p>PGx testing involves a simple genetic test typically done through a saliva sample or cheek swab. This sample is then analyzed to identify genetic variations that can impact how your body metabolizes medications. The results are used to
                         create a personalized medication plan in collboration with your healthcare provider.</p>
                    
                    <h3>Why choose HFSPGx for testing?</h3>
                    <p>
                        At HFSPGx, we are proud to offer cutting edge PGx (Pharmacogenomic) testing services in partnership with the leading laboratories in the field. Our mission is to empower individuals and healthcare providers with valuale genetic insights 
                        to presonalize medication and treatment plans for optimal outcomes.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area counter-bg counter-area-four">
    <div class="container">
        <div class="row">
            <!--<div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">850</h3>
                    <p>Patients Beds</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors  & Nurse</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">18</h3>
                    <p>Year Experience</p>
                </div>
            </div>-->
            <h3>Ready to take control of your health? Contact us to schedule your PGX test or for any inquiries.</h3>
        </div>
    </div>
</div>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>PGx Testing Process.</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Sample Collection</h3>
                                    <p>We offer convenient sample collection options, including at-home sample collection kit.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Lab Analysis</h3>
                                    <p>Our State of the art laboratory analyzes your genetic data with utmost precision.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Comprehensive Report</h3>
                                    <p>You'll receive a Comprehensive PGx report detailing your genetic profile and medicine recommendations.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Consultation</h3>
                                    <p>We are here to interpret your results analyzed guide you and your healthcare provider through your presonalized treatment options.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Services</span>
            <h2>Benefits</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Personalized Treatment</h3>
                        <p>PGx testing provides personalized medication recommendations based on
                             your genetic profile, ensuring you receive the most effective treatment.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Personalized Treatment</h3>
                        <p>PGx testing provides personalized medication recommendations based on
                            your genetic profile, ensuring you receive the most effective treatment.</p>
                            <a routerLink="/services-details">Read More</a>
                        </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Minimize Side Effects</h3>
                        <p>Reduce the risk of adverse reactions and side effects by using medications that are compatible with your genetic makeup.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Minimize Side Effects</h3>
                        <p>Reduce the risk of adverse reactions and side effects by using medications that are compatible with your genetic makeup.</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Cost Effective</h3>
                        <p>By avoiding trial-and-error with medications, PGx testing can potentially save you money on healthcare expenses.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Cost Effective</h3>
                        <p>By avoiding trial-and-error with medications, PGx testing can potentially save you money on healthcare expenses.</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>


            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Safer Mediation Management</h3>
                        <p>Ensure your safety by avoiding medications that are genetically incompatible with you.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Safer Mediation Management</h3>
                        <p>Ensure your safety by avoiding medications that are genetically incompatible with you.</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Optimize Medication Dosing</h3>
                        <p>PGx testing can help determine the ideal dosage for your medications, improving treatment efficacy.</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Optimize Medication Dosing</h3>
                        <p>PGx testing can help determine the ideal dosage for your medications, improving treatment efficacy.</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <!--<div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</section>


<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Blogs</span>
            <h2>Our latest blogs</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>